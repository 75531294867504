import "./styles.css";

import PartySocket from "partysocket";

import z from "zod";

const JoinMessage = z.object({
  type: z.literal("join"),
  id: z.string(),
});

const JoinMatchMessage = z.object({
  type: z.literal("joinmatch"),
  playerName: z.string(),
});

const JoinMatchResponseMessage = z.object({
  type: z.literal("joinmatchresponse"),
  id: z.number(),
  response: z.string(),
  matchID: z.string(),
  playerCount: z.number(),
});

const BallMessage = z.object({
  type: z.literal("ball"),
  id: z.number(),
  score: z.number(),
  matchID: z.string(),
});

const ResultMessage = z.object({
  type: z.literal("result"),
  id: z.number(),
});

const ScoreMessage = z.object({
  type: z.literal("score"),
  id: z.number(),
  playerName: z.string(),
  score: z.number(),
  playerIndex: z.number(),
  playerNos: z.number(),
});

const RemoveMessage = z.object({
  type: z.literal("remove"),
  id: z.number(),
});

const StartMatchMessage = z.object({
  type: z.literal("startmatch"),
});

const StartMatchScorerMessage = z.object({
  type: z.literal("startmatchscorer"),
  ballNo: z.number(),
});


const MatchResponseMessage = z.object({
  type: z.literal("matchresponse"),
  id: z.string(),
  nextmatch: z.number(),
  currentdate: z.number(),
  ballNo: z.number(),
});

const BallAddMessage = z.object({
  type: z.literal("balladd"),
  ballNo: z.number(),
});

const BallRemoveMessage = z.object({
  type: z.literal("ballremove"),
  ballNo: z.number(),
});

const WicketAddMessage = z.object({
  type: z.literal("wicketadd"),
  ballNo: z.number(),
});

const WicketRemoveMessage = z.object({
  type: z.literal("wicketremove"),
  ballNo: z.number(),
});

const BallListMessage = z.object({
  type: z.literal("balllist"),
});

const WicketListMessage = z.object({
  type: z.literal("wicketlist"),
});

const LeaveMessage = z.object({
  type: z.literal("leave"),
  message: z.string(),
});

const ResponseMessage = z.object({
  type: z.literal("response"),
  code: z.number(),
  ballNo: z.number(),
  message: z.string(),
});

const MatchOverMessage = z.object({
  type: z.literal("matchover"),
  id: z.number(),
});


const scoreSchema = z.object({
  id: z.string(),
  playerName: z.string(),
  score: z.number(),
  playerIndex: z.number(),
  playerNos: z.number(),
});

const ScoreCardMessage = z.object({
  type: z.literal("scorelist"),
  data: z.array(scoreSchema)
});

const Message = z.union([JoinMessage, BallMessage, ResultMessage, ScoreMessage, RemoveMessage, StartMatchMessage, StartMatchScorerMessage, MatchResponseMessage, 
  BallAddMessage, BallRemoveMessage, WicketAddMessage, WicketRemoveMessage, BallListMessage, WicketListMessage,
  JoinMatchMessage, JoinMatchResponseMessage, LeaveMessage, ResponseMessage, MatchOverMessage, ScoreCardMessage ]);

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(text: string) {
  output.appendChild(document.createTextNode(text));
  output.appendChild(document.createElement("br"));
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "1005",
});

let nextBallAlarm = 0;
let nextMatchAlarm = 0;
let currentTime = 1;
let matchID = "";

let intervalID = 0;
let seconds = 5;

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  add(`Received <-> ${event.data}`);
  
  add(`Checking data type <-> ${event.data.type}`);
  const result = Message.safeParse(JSON.parse(event.data));
  if (result.success === false)
    add("error:" + result.error);
  else
    add("result.success:" + result.success);
  
  if (result.success === true) 
  {
    const data = result.data;
    add("data.type:" + data.type);
    switch (data.type)
    {
      case "matchresponse":
                              add("inside matchresponse case");
                              nextMatchAlarm = Date.now() + 1 * 60 * 1000;
                              currentTime = Date.now();
                          
                              matchID = data.id;
                              //to start match updating the nextBallAlarm to the value of the nextMatchAlarm
                              nextBallAlarm = nextMatchAlarm;
                              intervalID = setInterval(()=> updateLoop(), seconds * 1000);
                              add("matchresponse set values:" + nextMatchAlarm + "::" + currentTime + "::" + matchID + "::"+ intervalID);
                              break;
    }
  }
  
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {
  add("Changed Connected!");
  add("Sending startmatch to server");
  localStorage.setItem("ballNo", "" + 1);
  conn.send(JSON.stringify({ type: "startmatchscorer", ballNo:  0, matchID: "1005"}));
});

function updateLoop()
{
  currentTime = Date.now();
  add("Time check:" + nextBallAlarm + "::" + currentTime);
  if (currentTime > nextBallAlarm)
  {
    let ballNo = 1;
    ballNo = parseInt(localStorage.getItem("ballNo") || "1") ;
    add("ballNo :" + ballNo);
    if (((ballNo)) < 121)
    {
      let randomScore = Math.floor(Math.random() * (8 - 1 + 1)) + 1;
      // localStorage.setItem(matchID+"ball"+ballNo, ""+randomScore);
      conn.send(JSON.stringify({ type: "ball", id: ballNo || 1 , score: randomScore, matchID: matchID}));
      console.log("Ball Data:" + JSON.stringify({ type: "ball", id: ballNo || 1 , score: randomScore, matchID: matchID}));
      localStorage.setItem("ballNo", "" + (ballNo + 1));
      //Next Ball to Serve after 10 seconds
      nextBallAlarm = Date.now() + 5 * 1000;
    }
    else
    {
      add("After 120 balls match over" + intervalID);
      localStorage.setItem("ballNo", "1");
      conn.send(JSON.stringify({ type: "matchover", id: matchID}));
      clearInterval(intervalID);
      // //Next Match to Start after 25 minutes
      // nextBallAlarm = Date.now() + 25 * 60 * 1000;
      nextBallAlarm = 0;
    }

  }
}


